import { createThirdwebClient, getContract, getGasPrice } from "thirdweb";
import { polygon } from "thirdweb/chains";

export const usdtAddress = "0xc2132D05D31c914a87C6611C10748AEb04B58e8F";
export const contractAddress = "0xD75Cf648b883091e14d81660c014c8Ca2844Ec28";

export const client = createThirdwebClient({
  clientId: "53ccb9f382e1e5a5c36346a83394803e",
});

export const chain = polygon;

export const contract = getContract({
  client,
  address: contractAddress,
  chain,
});

export const tokenContract = getContract({
  client,
  address: usdtAddress,
  chain,
});

export const getIncreasedGasPrice = async () => {
  const gasPrice = await getGasPrice({ client, chain });
  const increasedGasPrice = (gasPrice * BigInt(120)) / BigInt(100);
  return increasedGasPrice;
};
